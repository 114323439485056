import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      form.current.name.value.trim() === "" ||
      form.current.email.value.trim() === "" ||
      form.current.message.value.trim() === "" ||
      form.current.phone.value.trim() === ""
    ) {
      toast.error("الرجاء تعبئة جميع الحقول");
      return;
    }

    emailjs
      .sendForm(
        "service_fkcv54t",
        "template_aucs2d6",
        form.current,
        "z-m3ObEhLvDPdynLY"
      )
      .then(
        () => {
          toast.success("تم تسجيل بياناتك بنجاح، سنتواصل معك في أقرب وقت");
          form.current.reset();
        },
        () => {
          toast.error("حدث خطأ أثناء تسجيل بياناتك، يرجى المحاولة مرة أخرى.");
        }
      );
  };

  return (
    <div className="min-h-screen flex  items-center justify-center">
      <ToastContainer />
      <div className="container py-8 ">
        <form
          onSubmit={handleSubmit}
          ref={form}
          id="contact"
          className="bg-white
                w-full
                flex flex-col gap-8
                p-8
                mx-auto
                rounded-md
                shadow-lg
                lg:w-1/2
                border-2
                border-gray-300"
        >
          <p className="text-xl text-center">
            مرحبا بك في منفعة، سجل بياناتك وسنتواصل معك في أقرب وقت
          </p>
          <input
            type="text"
            placeholder="الاسم الكامل"
            name="name"
            className="
            border-2
            border-gray-300
            focus:outline-none
            focus:ring-2
            focus:ring-primary
            focus:border-transparent
            p-2
            rounded-md
    "
          />
          <input
            type="text"
            placeholder="رقم الجوال"
            name="phone"
            className="
            border-2
            border-gray-300
            focus:outline-none
            focus:ring-2
            focus:ring-primary
            focus:border-transparent
            p-2
            rounded-md
    "
          />
          <input
            type="email"
            placeholder="البريد الإلكتروني"
            name="email"
            className="
            border-2
            border-gray-300
            focus:outline-none
            focus:ring-2
            focus:ring-primary
            focus:border-transparent
            p-2
            rounded-md
    "
          />
          <textarea
            placeholder="الغرض من التسجيل"
            name="message"
            className="resize-y
            border-2
            border-gray-300
            focus:outline-none
            focus:ring-2
            focus:ring-primary
            focus:border-transparent
            p-2
            rounded-md"
          ></textarea>
          <button className="px-8 bg-green text-white py-4 rounded-md font-bold">
            إرسال
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
