import ContactUs from "../Components/ContactUs";
import Manafaa from "../Components/Manafaa";
import Numbers from "../Components/Numbers";
import Partners from "../Components/Partners";
import RepeatedQuestions from "../Components/RepeatedQuestions";
import shape1 from "../assets/bg-shapes/Group4.svg";
import shape2 from "../assets/bg-shapes/Group3.svg";
import HomeHeader from "../Components/HomeHeader";
import Contact from "../Components/Contact";

function Home() {
  return (
    <>
      {/* <HomeHeader />
      <Numbers />
      <div className="bg-[#F7F7F8] pb-24  relative ">
        <div className="z-10 relative overflow-hidden">
          <Manafaa />
          <Partners />
          <RepeatedQuestions />
          <ContactUs />
        </div>
        <img
          src={shape1}
          alt="shape"
          className="absolute top-4 opacity-25 w-[80px] md:w-fit left-0 z-[0]"
        />
        <img
          src={shape2}
          alt="shape"
          className="absolute opacity-25 w-[50px] top-[584px] -right-4 xl:right-4 z-[0]"
        />
      </div> */}
      <Contact />
    </>
  );
}

export default Home;
