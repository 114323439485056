import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Layout from "./Components/layout/Layout";
import Home from "./pages/Home";
import Services from "./pages/Services";
const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        element: <Home />,
        path: "/",
      },
      // {
      //   element:<Services/>,
      //   path:'/:serviceId'
      // }
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
