import { motion, useScroll } from "framer-motion";
import Footer from "../Footer";
import Header from "../Header";
import { Outlet } from "react-router-dom";

function Layout() {
  const { scrollYProgress } = useScroll();

  return (
    <>
      {/* <motion.div
        className={`fixed left-0 right-0 top-0 bg-green h-2 origin-[0]  scale-x-[${scrollYProgress}] z-[2000]`}
        style={{ scaleX: scrollYProgress }}></motion.div>
      <Header /> */}
      <Outlet />
      {/* 
      <Footer /> */}
    </>
  );
}

export default Layout;
