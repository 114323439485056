import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Service from "../Components/Manfaa/Service";
import ServiceHeader from "../Components/Manfaa/ServiceHeader";
import data from "./data.json";
function Services() {
  const { serviceId } = useParams();
  const [currentService, setCurrentService] = useState();
  const [serviceHeader, setServiceHeader] = useState();
  useEffect(() => {
    const service = data.services.find((service) => {
      return service.id === +serviceId;
    });
    setCurrentService(service);
    setServiceHeader({
      title: service.title,
      content: service.content,
      id: service.id,
    });
  }, [serviceId]);
  if (currentService)
    return (
      <div>
        <ServiceHeader serviceHeader={serviceHeader} service={currentService} />
        <Service
          programs={currentService.programs}
          setServiceHeader={setServiceHeader}
        />
      </div>
    );
}

export default Services;
